<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin::Incident-->
    <div class="row">
      <div
        class="col-12 col-md-2 pt-0 pb-0"
        v-if="
          permission.some((item) => item.name === 'can_view_incident_report')
        "
      >
        <div class="card gutter-b">
          <b-form-select
            v-model="selectCustomer"
            :options="filterCustomer"
          ></b-form-select>
        </div>
      </div>
      <div
        class="col-12 col-md-2 pt-0 pb-0"
        v-if="
          permission.some((item) => item.name === 'can_view_incident_report')
        "
      >
        <div class="card gutter-b">
          <b-form-select
            v-model="selected.customerBranch"
            :options="filterCustomerBranch"
          ></b-form-select>
        </div>
      </div>
      <div class="col-xxl-3 col-12 col-md-3 pt-0 pb-0" v-show="false">
        <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
          <b-input-group>
            <b-form-select
              id="sort-by-select"
              v-model="refSortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- Sort by --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="refSortDirectionChange"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-xxl-2 col-12 col-md-2 pt-0 pb-0">
        <div class="card gutter-b">
          <b-form-datepicker
            v-model="params.start_date"
            placeholder="Start date"
            today-button
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="col-xxl-2 col-12 col-md-2 pt-0 pb-0">
        <div class="card gutter-b">
          <b-form-datepicker
            v-model="params.end_date"
            placeholder="End date"
            today-button
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="col-xxl-3 col-12 col-md-3 pt-0 pb-0">
        <div class="card gutter-b">
          <b-button variant="primary" class="w-100" @click="exportExcell"
            >Export Excel</b-button
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12 col-12 col-md-12">
        <b-card>
          <div class="row justify-content-between">
            <div class="col-md-1">
              <b-form-group class="mb-0">
                <b-form-select
                  id="per-page-select"
                  v-model="refPerpage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group class="mb-0">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="menu-icon flaticon-search"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="params.query"
                    placeholder="Search"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              hover
              :items="listTable"
              :fields="columns"
              :per-page="params.limit"
              :current-page="refCurrentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-empty
            >
              <template #cell(created_at)="row">
                <span class="mr-2">
                  {{ dateToISOString(row.item.created_at) }}
                </span>
              </template>
              <template #cell(time)="row">
                <span class="mr-2">
                  {{ timeFormat(row.item.created_at) }}
                </span>
              </template>
              <template #cell(images)="row">
                <img
                  @click="previewImage(row.item.images[0].image)"
                  :src="row.item.images[0].image"
                  style="
                    max-width: 100px;
                    max-height: 100px;
                    object-fit: scale-down;
                    cursor: pointer;
                  "
                />
              </template>
            </b-table>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-3">
              <b-pagination
                v-model="refCurrentPage"
                :total-rows="totalRows"
                :per-page="params.limit"
                size="md"
                align="fill"
                class="ml-auto"
                style="padding: 0"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <b-modal
      size="lg"
      ref="modal-image-preview"
      hide-footer
      title="Preview Image"
    >
      <div style="display: flex; justify-content: center" id="imageid">
        <img
          :src="selectedImagePreview"
          class="ml-auto mr-auto"
          style="width: 100%"
        />
      </div>
      <!-- <b-button
        variant="primary"
        class="w-100"
        style="margin-top: 21px"
        @click="downloadPDF()"
        >Print</b-button
      > -->
    </b-modal>
    <!--end::Incident-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debounce } from "debounce";
import { dateToISOString, timeFormat } from "@/utils/helper";
import { mapGetters } from "vuex";
import mixin from "@/mixins/index";

export default {
  name: "Incident",
  mixins: [mixin],
  data() {
    let dateNow = dateToISOString(
      new Date(new Date()).setDate(new Date().getDate() + 1)
    );
    return {
      filterPeriod: [
        { value: null, text: "Filter Periode" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      filterCustomer: [{ value: null, text: "Filter Site Group" }],
      filterCustomerBranch: [{ value: null, text: "Filter Customer" }],
      selected: {
        customer: null,
        customerBranch: null,
        area: null,
        position: null,
      },
      isLoading: false,
      // Table
      rowStyleOption: {
        stripe: true,
      },
      search: "",
      pageIndex: 1,
      totalRows: 10,
      currentPage: 1,
      pageSize: 10,
      perPage: 10,
      pageOptions: [10, 15, { value: 100, text: "Show a lot" }],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      columns: [
        {
          key: "employee.customer_branch.name",
          label: "Customer",
          align: "left",
          sortBy: "",
        },
        {
          key: "employee.name",
          label: "Petugas",
          align: "left",
          sortBy: "",
        },
        {
          key: "created_at",
          label: "Tanggal",
          align: "left",
          sortBy: "created_at",
          sortable: true,
        },
        {
          key: "time",
          label: "Jam",
          align: "left",
          sortBy: "",
        },
        {
          key: "location",
          label: "Lokasi",
          align: "left",
          sortBy: "location",
          sortable: true,
        },
        {
          key: "related_parties",
          label: "Pihak Terkait",
          align: "left",
          sortBy: "related_parties",
          sortable: true,
          width: "5%",
        },
        {
          key: "steps",
          label: "Langkah",
          align: "left",
          sortBy: "steps",
          sortable: true,
          width: "20%",
        },
        {
          key: "chronology",
          label: "Kronologis",
          align: "left",
          sortBy: "chronology",
          sortable: true,
          width: "20%",
        },
        {
          key: "incident_type.name",
          label: "Jenis",
          align: "left",
        },
        {
          key: "images",
          label: "Foto",
          align: "left",
          width: "5%",
        },
      ],
      sortBy: "",
      sortDesc: false,
      listTable: [],
      filter: null,
      filterOn: [],
      params: {
        query: "",
        sort: "created_at",
        order: "desc",
        limit: 10,
        offset: 0,
        start_date: "2022-01-01",
        end_date: dateNow,
      },
      selectedImagePreview: "",
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    tableData() {
      return this.listTable;
    },

    totalCount() {
      return this.listTable.length;
    },

    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },

    refPerpage: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.perPage = value;
        this.params.limit = value;
      },
    },

    refSortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.sortBy = value;
        this.params.sort = value;
      },
    },

    refSortDirectionChange: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.sortDesc = value;
        if (value) this.params.order = "desc";
        else this.params.order = "asc";
      },
    },

    sortOptions() {
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    selectCustomer: {
      get() {
        return this.selected.customer;
      },
      set(value) {
        this.selected.customer = value;
        if (value === null) {
          this.selected.customerBranch = null;
          this.filterCustomerBranch = [{ value: null, text: "Pilih Site" }];
        } else this.getCustomerBranchList(value);
      },
    },
  },
  async mounted() {
    this.filterCustomer = await this.getCustomer();
    this.selected.customer = this.filterCustomer[1].value;
    if (!this.permission.some((item) => item.name === "can_create_customer")) {
      this.getCustomerBranchList(this.selected.customer);
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Kejadian" },
    ]);
  },
  methods: {
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    // page size change
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    async getTabel() {
      this.isLoading = true;
      try {
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/report/incident`,
            {
              params: {
                customer_id: this.selected.customer,
                customer_branch_id: this.selected.customerBranch,
                ...this.params,
              },
            }
          )
          .then(({ data }) => {
            this.listTable = data.list;
            this.totalRows = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getCustomerBranchList(customer_id) {
      this.isLoading = true;
      if (
        !this.permission.some((item) => item.name === "can_create_customer")
      ) {
        const customerBranch = this.$store.getters.currentUser.customer_branch;
        this.filterCustomerBranch = [{ value: null, text: "Select All" }];
        customerBranch.map((item) => {
          this.filterCustomerBranch.push({
            value: item.id,
            text: item.name,
          });
        });
        this.selected.customerBranch = this.filterCustomerBranch[1].value;
      } else {
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer/${customer_id}/customer-branches`
          )
          .then(({ data }) => {
            this.filterCustomerBranch = [{ value: null, text: "Select All" }];
            data.list.map((item) => {
              this.filterCustomerBranch.push({
                value: item.id,
                text: item.name,
              });
            });
          });
        this.selected.customerBranch = this.filterCustomerBranch[0].value;
      }
      this.isLoading = false;
    },
    processChange: debounce(function () {
      this.getTabel();
    }, 500),
    dateToISOString,
    timeFormat,
    async exportExcell() {
      const customerId = this.selected.customer;
      const customerBranchId = this.selected.customerBranch;
      await this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/report/incident/export`,
          {
            params: {
              start_date: this.params.start_date,
              end_date: this.params.end_date,
              customer_id: customerId || null,
              customer_branch_id: customerBranchId || null,
              limit: 100000,
            },
          }
        )
        .then(({ data }) => {
          window.location.href = data.file;
        })
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message || "Terjadi Kesalahan", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    previewImage(url) {
      this.selectedImagePreview = url;
      this.$refs["modal-image-preview"].show();
    },
  },
  watch: {
    params: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
  },
};
</script>
